<style>
.bg-readonly input, .bg-readonly textarea {
    background-color: rgb(235, 235, 235);
}
.remove-button, .add-button {
  font-size: 1.5em;
  background-color: #ff5c5c; /* Red color for remove button */
  color: #fff; /* White text color for buttons */
  border: none;
  cursor: pointer;
  padding: 0.2em 0.5em;
  margin: 0.2em;
  border-radius: 5px;
}
.add-button {
  background-color: #5cb85c; /* Green color for add button */
}
</style>
<template>
    <vx-card :title="'Edit Inbound - Customer Return Form'">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Customer/Ship-to Code</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="data.customer_code" :readonly="true" class="w-full bg-readonly"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>SO/Invoice Code</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="data.so_code" :readonly="true" class="w-full bg-readonly"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Sales Return Data</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="data.sales_return_data " :readonly="true" class="w-full bg-readonly"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>GIR Data</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="data.gir_data " :readonly="true" class="w-full bg-readonly"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Posting Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <!-- <Date :value="this.data.receive_date" @selected="(val) => {
                    this.data.receive_date = val
                }"></Date> -->
                <datepicker name="date" :inline="false" format="yyyy-MM-dd"
                    v-model="data.receive_date" placeholder="Select Date"
                    :cleared="() => {
                        data.receive_date = null
                    }">
                </datepicker>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Note</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-textarea :readonly="statusReadonly" v-model="data.note" width="100%" :class="statusReadonly ? 'bg-readonly' : ''"/>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Attachment</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <input type="file" ref="attechment" accept=".jpg,.jpeg, .png" @change="handleChangeAttachment" />

            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%" v-if="this.data.fileUrl != ''">
            <div class="vx-col sm:w-1/3 w-full flex items-center">

            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <div class="op-block mt-5">
                    <vs-list>
                        <vs-list-item title="File 1">
                            <div class="vx-input-group flex">
                                <vs-button size="small" color="success" icon-pack="feather" icon="icon-download"
                                    title="Download" class="mr-1" @click="openTab(fileUrl)" />
                                <!-- <vs-button size="small" color="danger" icon-pack="feather" icon="icon-trash"
                                    title="Delete" /> -->
                            </div>
                        </vs-list-item>
                    </vs-list>
                </div>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full mb-6">
                <table width="100%" class="vs-table vs-table--tbody-table mb-6">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="7%">SKU Code</th>
                            <th width="15%">SKU Description</th>
                            <th width="5%">Return HU</th>
                            <th width="5%">Return Qty</th>
                            <template v-if="data.shipment_number !== '' && data.shipment_number !== null">
                                <th width="5%">Pickup Qty</th>
                            </template>
                            <th width="12%">Supplier</th>
                            <th width="6%">Receive HU</th>
                            <th width="5%">Receive Qty</th>
                            <th width="5%">Delta Qty Pcs</th>
                            <th width="15%">ED</th>
                            <th width="8%">Batch</th>
                            <th width="10%">Checked Status</th>
                            <th width="5%"></th>
                        </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in items" :key="index" style="padding-bottom: 5px">
                        <td class="td vs-table--td" style="padding: 5px">
                          <vs-input :value="item.item_code" :readonly="true" class="w-full bg-readonly" v-show="!item.hideFields"></vs-input>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                          <vs-input :value="item.item_name" :readonly="true" class="w-full bg-readonly" v-show="!item.hideFields"></vs-input>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                          <vs-input :value="item.item_unit" :readonly="true" class="w-full bg-readonly" v-show="!item.hideFields"></vs-input>
                          <input type="hidden" :value="item.item_unit_id" />
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                          <vs-input :value="item.quantity" :readonly="true" class="w-full bg-readonly" v-show="!item.hideFields"></vs-input>
                        </td>
                        <template v-if="item.pickup_qty !== 0">
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="item.pickup_qty" :readonly="true" class="w-full bg-readonly" v-show="!item.hideFields"></vs-input>
                            </td>
                        </template>
                        <td class="td vs-table--td" style="padding: 5px">
                            <SupplierSelect :value="item.supplier"
                                :base-url="`/api/wms/v1/inbound/sales-return-receive/supplier/${item.item_id}`"
                                label="name" track-by="name" @selected="(v) => {
                                    item.supplier = v;
                                    item.supplier_id = v.id;
                                    item.supplier_code = v.code;
                                }"></SupplierSelect>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                            <CustomMSelect :value="item.item_unit_receive"
                                :base-url="`/api/wms/v1/master/item-unit/item/${item.item_id}`"
                                label="name" track-by="name" @selected="(v) => {
                                    item.item_unit_receive = v;
                                    item.item_unit_id = v.id;
                                    item.amount_uom_receive = v.amount_uom;
                                    updateAmountUom(index);
                                }"></CustomMSelect>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                          <vs-input type="text" @input="calculate(index)" v-model="item.qty" class="w-full"></vs-input>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                          <vs-input :value="item.delta_quantity" :readonly="true" class="w-full bg-readonly"></vs-input>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                            <template v-if="item.setting_ed == '0'">
                                <vs-input :value="formatDate(item.expired_date)" :readonly="true" class="w-full bg-readonly"></vs-input>
                            </template>
                            <template v-else>
                                <datepicker name="date" :inline="false" format="yyyy-MM-dd"
                                    v-model="item.expired_date" placeholder="Select Date"
                                    @input="updateBatch(index)"
                                    :cleared="() => {
                                        item.expired_date = null
                                    }">
                                </datepicker>
                            </template>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                            <template v-if="item.setting_batch !== 'Batch'">
                                <vs-input :value="item.batch" :readonly="true" class="w-full bg-readonly"></vs-input>
                            </template>
                            <template v-else>
                                <vs-input type="text" v-model="item.batch" class="w-full"></vs-input>
                            </template>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                          <v-select v-model="item.checked_status" :options="statusOptions"/>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                          <button @click="removeItem(index)" v-if="index !== 0 && items[index].hideFields" class="remove-button">&#45;</button>
                          <button :disabled="item.child_item || item.qty == 0" @click="addItem(index)" v-else class="add-button">&#43;</button>
                        </td>
                      </tr>
                    </tbody>
                </table>
            </div>
        </div>
          <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/1 w-full">
                <div
                style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="mr-2 mb-3" @click="handleSubmit(false)">Save to draft</vs-button>
                    <vs-button class="mb-2 mr-3" @click="handleSubmit(true)">Save and Confirm</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>

import _ from "lodash";
import Warehouse from "@/components/Warehouse.vue";
import Date from "@/components/Date.vue";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import CustomMSelect from "@/components/CustomMultiSelect.vue";
import SupplierSelect from "./component/Supplier.vue";

import vSelect from "vue-select";
export default {
    components: {
        Warehouse,
        Date,
        Datepicker,
        vSelect,
        CustomMSelect,
        SupplierSelect
    },
    data() {
        return {
            data: {
                id: null,
                customer_code: null,
                so_code: null,
                code: null,
                gir_number: null,
                sales_return_data: null,
                gir_data: null,
                sales_return_id: null,
                sales_return_code: null,
                receive_date: null,
                note: null,
                file: null,
                status: null,
                srr_id: null,
                tpr_id: null,
                parent_id: null,
                fileUrl: '',
                setting: '',
                shipment_number: '',
              },
            items: [],
            statusOptions: [
                { label: 'Good', value: 'Good' },
                { label: 'Bad', value: 'Bad' },
                { label: 'Expired', value: 'Expired' },
            ],
            statusReadonly: false,
            stsDisable: false,
        }
    },
    mounted() {
        // var now = moment()
        // this.data.receive_date = now
        // now.add(1, 'days')

        this.id = this.$route.params.id;
        if (this.id) { // sto id
            this.getData()
        }
    },
    methods: {
        updateBatch(index) {
            console.log("DATA ITEM",this.items[index])
            const formattedDate = moment(this.items[index].expired_date).format('YYYYMMDD');
            this.items[index].batch = `${formattedDate}`;
        },
        updateAmountUom(index) {
            console.log("DATA ITEM",this.items[index])
            if (this.items[index].qty != null && this.items[index].qty !== 0) {
                const currentItem = this.items[index];
                let previousDeltaQuantity = 0; // Menyimpan nilai delta dari loop sebelumnya

                this.items.forEach((val, idx) => {
                    // Cek kelompok item berdasarkan kode, unit, dan quantity
                    if (val.item_code === currentItem.item_code &&
                        val.item_unit === currentItem.item_unit &&
                        val.quantity === currentItem.quantity) {

                        if (val.hideFields === false) {
                            // Iterasi pertama untuk kelompok item (hideFields == false)
                            if (val.pickup_qty && val.pickup_qty !== 0) {
                                // Jika ada pickup_qty, gunakan dalam perhitungan
                                val.delta_quantity = (val.pickup_qty * val.return_amount_uom) - (val.qty * val.amount_uom_receive);
                            } else if(val.qty && val.qty !== 0){
                                // Jika tidak ada pickup_qty, gunakan quantity
                                val.delta_quantity = (val.quantity * val.return_amount_uom) - (val.qty * val.amount_uom_receive);
                            }
                            // Set nilai delta_quantity ini sebagai nilai awal untuk iterasi berikutnya
                            previousDeltaQuantity = val.delta_quantity;
                        } else {
                            // Iterasi selanjutnya dalam kelompok (hideFields == true)
                            // Menggunakan delta_quantity dari iterasi sebelumnya
                            val.delta_quantity = previousDeltaQuantity - (val.qty * val.amount_uom_receive);
                            // Perbarui nilai previousDeltaQuantity untuk digunakan dalam iterasi berikutnya
                            previousDeltaQuantity = val.delta_quantity;
                        }
                    }
                });
            }
        },
      addItem(index) {
          // Check if the current row has already added a new row
          if (!this.items[index].hideFields) {
            let count = index;
            this.items.forEach((val) => {
                if (val.item_code === this.items[index].item_code && val.item_unit === this.items[index].item_unit && val.quantity === this.items[index].quantity) {
                    count++;
                }
            });
            let parent_delta = 0;
            if(count > 0){
                let indexParent = count - 1;
                console.log("index count",indexParent);
                parent_delta = this.items[indexParent].delta_quantity
            }
            const newItem = {
                item_code: this.items[index].item_code,
                item_name: this.items[index].item_name,
                item_unit: this.items[index].item_unit,
                // item_unit_id: this.items[index].item_unit_id,
                quantity: this.items[index].quantity,
                pickup_qty: this.items[index].pickup_qty,
                qty: '',
                delta_quantity: parent_delta,
                delta_quantity_parent: parent_delta,
                batch: this.items[index].batch,
                expired_date: this.items[index].expired_date,
                checked_status: 'Bad',
                setting_ed : this.items[index].setting_ed,
                // expired_date_from_so : this.items[index].expired_date_from_so,
                setting_batch : this.items[index].setting_batch,
                // batch_from_so : this.items[index].batch_from_so,
                hideFields: true, // Set the flag to true for the current row
                supplier_code: this.items[index].supplier_code,
                supplier_id: this.items[index].supplier_id,
                item_id: this.items[index].item_id,
                return_amount_uom: this.items[index].return_amount_uom,
            };
            if(index == 0){
                this.items.splice(count, 0, newItem);
            } else {
                this.items.splice(count, 0, newItem);
            }
            // this.items.splice(index + 1, 0, newItem);
            console.log("index", index)
        }
        const itemCounts = {};
        this.items.forEach((val) => {
            const itemCode = val.item_code + " - " + val.item_unit;
            itemCounts[itemCode] = (itemCounts[itemCode] || 0) + 1;
        });

        // Set child_item flag based on the count
        this.items.forEach((val, i) => {
            if (itemCounts[val.item_code] > 2) {
            this.items[i].child_item = true;
            }
        });
        //   console.log('lenght',this.items[index].item_code)
        //   if(this.items.length == 3){
        //       this.items[index].child_item = true;
        //   }
        },
        removeItem(index) {
          this.items.splice(index, 1);
          this.items[index-1].child_item = false;
        },
        getData() {
          console.log('tes',this.$store.state.inbound.customerReturn.baseUrlPath  + "/" + this.id)
            this.$vs.loading();
            this.$http
                .get(this.$store.state.inbound.customerReturn.baseUrlPath + "/" + this.id)
                .then((resp) => {
                    if (resp.code == 200) {
                        console.log("data parent",resp.data)
                        var now = moment().toDate();
                        this.data.parent_id = resp.data.parent_id
                        this.data.id = resp.data.sr_id
                        this.data.customer_code = resp.data.customer_code
                        this.data.so_code = resp.data.so_code
                        this.data.so_id = resp.data.so_id
                        this.data.sales_return_data = resp.data.sales_return_code + " " + resp.data.gir_number
                        this.data.gir_data = resp.data.code
                        this.data.sales_return_id = resp.data.sales_return_id
                        this.data.sales_return_code = resp.data.sales_return_code
                        this.data.code = resp.data.code
                        this.data.gir_number = resp.data.gir_number
                        this.data.receive_date = resp.data.receive_date == null ? now : resp.data.receive_date
                        this.data.tpr_id = resp.data.tpr_id != 0 ? resp.data.tpr_id : 0
                        this.data.note = resp.data.note
                        this.data.status = resp.data.status
                        this.data.srr_id = resp.data.srr_id
                        this.data.wh_id = resp.data.wh_id   
                        this.data.setting = resp.data.setting.value
                        this.data.shipment_number = resp.data.shipment_number
                        console.log("setting putaway",this.data.setting)
                        var sameitem = ""
                        var totdelta = 0
                        var hasildelta = 0
                        resp.data.lines.forEach(el =>{
                            let expired_date = null
                            let batch = ""
                            console.log('ed',el.expired_date)
                            if(el.is_expired == "0" && el.expired_date == "0001-01-01T00:00:00Z"){
                                expired_date = moment().add(1, 'years').format('YYYY-MM-DD');
                            } else if(el.expired_date == "0001-01-01T00:00:00Z"){
                                expired_date = null
                            } else {
                                expired_date = el.expired_date
                            }
                            if(el.batch == "0000"){
                                batch = ""
                            } else {
                                batch = el.batch
                            }
                            totdelta += el.delta_quantity
                            hasildelta = el.return_qty - el.qty
                          const newItem = {
                            item_code: el.item_code,
                            item_name: el.item_name,
                            item_unit: el.item_unit,
                            item_unit_id: el.item_unit_id,
                            qty: el.qty,
                            quantity: el.return_qty,
                            pickup_qty: el.pickup_qty,
                            delta_quantity: el.delta_quantity,
                            delta_quantity_parent: el.return_qty,
                            // delta_quantity: 0,
                            setting_ed: el.is_expired,
                            setting_batch: el.name,
                            batch: batch,
                            expired_date: expired_date,
                            checked_status: el.checked_status,
                            hideFields: (sameitem == el.item_code + el.item_unit + el.return_qty) ? true : false,
                            child_item : false,
                            supplier_code: el.supplier_code,
                            supplier_id: el.supplier_id,
                            item_id: el.item_id,
                            amount_uom_receive: el.amount_uom,
                            item_unit_receive: {
                                amount_uom: el.amount_uom,
                                id: el.item_unit_id,
                                item_id: el.item_id,
                                level: el.level,
                                name: el.unit_name,
                                unit_id: el.unit_id
                            },
                            supplier: {
                                id: el.supplier_id,
                                code: el.supplier_code,
                                name: el.supplier_name
                            },
                            return_amount_uom: el.return_amount_uom
                          }
                          this.items.push(newItem)
                          sameitem = el.item_code + el.item_unit + el.return_qty
                        })
                        if(this.data.parent_id == 0 && totdelta > 0){
                            this.stsDisable = true
                        }
                        this.data.fileUrl = resp.data.file_url
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        handleBack() {
            this.$router.push({
                name: this.$store.state.inbound.customerReturn.baseRouterName,
            });
        },
        handleChangeAttachment(event) {
            this.file = event.target.files ? event.target.files[0] : null;
        },
        handleSubmit(isConfirm = false) {
            var proceed = true;
            try{
                const groupedByItemCode = this.items.reduce((accumulator, current) => {
                    const itemCode = current.item_code + " - " + current.item_unit + " - " + current.quantity;
                    accumulator[itemCode] = accumulator[itemCode] || { sum: 0, qty: 0 };
                    accumulator[itemCode].sum += parseInt(current.qty) * current.amount_uom_receive;
                    if(current.pickup_qty != 0){
                        accumulator[itemCode].qty = current.pickup_qty * current.return_amount_uom;
                    }else {
                        accumulator[itemCode].qty = current.quantity * current.return_amount_uom;
                    }
                    return accumulator;
                }, {});

                for (const itemCode in groupedByItemCode) {
                    const { sum, qty } = groupedByItemCode[itemCode];                        
                    if (qty < sum) {
                        const message = `quantity of item with code ${itemCode} must be ${qty}`;
                        this.$vs.dialog({
                            type: 'alert',
                            color: 'danger',
                            title: 'Information',
                            text: message,
                            acceptText: 'OK',
                        });
                        proceed = false
                        throw new Error('BreakException');                            
                    }else{
                        proceed = true
                    }
                    if(qty != sum){
                        this.data.is_partial = "yes"
                    }
                }

                let TotalQtyReceive = 0;
                this.items.forEach((el, idx) => { 
                    TotalQtyReceive += parseInt(el.qty);                         
                    if (this.items[idx].batch == null || this.items[idx].batch == '') {
                        this.$vs.dialog({
                            type: 'alert',
                            color: 'danger',
                            title: 'Information',
                            text: 'Batch is required!',
                            acceptText: 'OK',
                        });
                        proceed = false
                        throw new Error('BreakException');
                    } else if (this.items[idx].expired_date == null || this.items[idx].expired_date == ''){
                        this.$vs.dialog({
                            type: 'alert',
                            color: 'danger',
                            title: 'Information',
                            text: 'Expired date is required!',
                            acceptText: 'OK',
                        });
                        proceed = false
                        throw new Error('BreakException');
                    } else if(this.items[idx].checked_status == null || this.items[idx].checked_status == ''){
                        this.$vs.dialog({
                            type: 'alert',
                            color: 'danger',
                            title: 'Information',
                            text: 'Checked status is required!',
                            acceptText: 'OK',
                        });
                        proceed = false
                        throw new Error('BreakException');   
                    } else if(this.items[idx].supplier == null){
                        this.$vs.dialog({
                            type: 'alert',
                            color: 'danger',
                            title: 'Information',
                            text: 'Supplier is required!',
                            acceptText: 'OK',
                        });
                        proceed = false
                        throw new Error('BreakException');   
                    } else {
                        proceed = true
                    }
                }); 
                if (TotalQtyReceive == 0){
                    this.$vs.dialog({
                        type: 'alert',
                        color: 'danger',
                        title: 'Information',
                        text: 'Must at least receive one item !',
                        acceptText: 'OK',
                    });
                    proceed = false
                    throw new Error('BreakException');
                } else {
                    proceed = true
                }                                   
            }catch (e) {
                if (e.message !== 'BreakException') throw e;
            }
            if(proceed){
                if(isConfirm){
                    this.data.status = 2,
                    this.data.confirm = 1
                }
                this.data.receive_date = moment(this.data.receive_date).format('YYYY-MM-DD')
                // console.log("tes date",this.data.receive_date)
                let itemsMap = {};
                this.items.forEach((item, index) => {
                    let key = `${item.item_code}-${item.item_unit}`;
                    if (!itemsMap[key]) {
                        itemsMap[key] = [];
                    }
                    itemsMap[key].push(index);
                });
                this.items.forEach((val, i) => {
                    let key = `${val.item_code}-${val.item_unit}`;
                    let indices = itemsMap[key];
                    let lastIndex = indices[indices.length - 1];

                    if (i === lastIndex && this.items[i].delta_quantity > 0) {
                        this.items[i].is_partial = 1;
                    } else {
                        this.items[i].is_partial = 0;
                    }
                    this.items[i].expired_date = moment(val.expired_date).format('YYYY-MM-DD')
                    this.items[i].qty = parseInt(val.qty)
                    if(val.checked_status.value != undefined){
                        this.items[i].checked_status = val.checked_status.value
                    } else {
                        this.items[i].checked_status = val.checked_status
                    }
                    console.log("tes date",this.items[i].expired_date)
                })
    
                let formData = new FormData();
                formData.append("file", this.file);
                formData.append("data", JSON.stringify(this.data))
                formData.append("lines", JSON.stringify(this.items))
                console.log('data',JSON.stringify(this.data))
                console.log('lines',JSON.stringify(this.items))
                this.$vs.loading();
                let http = null
    
                if (this.data.srr_id != null) {
                    http = this.$http.put(this.$store.state.inbound.customerReturn.baseUrlPath + "/" + this.data.srr_id, formData)
                } else {
                    http = this.$http.post(this.$store.state.inbound.customerReturn.baseUrlPath, formData)
                }
    
                http.then((resp) => {
                    console.log(resp)
                    if (resp.code == 200) {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
    
                        this.$vs.loading.close();
                        this.draw++;
                        this.handleBack()
                    } else {
                        this.$vs.loading.close();
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                            duration: 10000,
                        });
                    }
                })
            }
        },
        calculate(index){
            // if(this.items[index].hideFields == true){
            //     this.items[index].delta_quantity = this.items[index - 1].delta_quantity - (this.items[index].qty * this.items[index].amount_uom_receive);
            // } else {
            //     if(this.items[index].pickup_qty != 0){
            //         this.items[index].delta_quantity = (this.items[index].pickup_qty * this.items[index].return_amount_uom) - (this.items[index].qty * this.items[index].amount_uom_receive);
            //     } else {
            //         this.items[index].delta_quantity = (this.items[index].quantity * this.items[index].return_amount_uom) - (this.items[index].qty * this.items[index].amount_uom_receive);
            //     }
            // }
            this.items[index].qty = this.items[index].qty.replace(/\D/g, '');
            const currentItem = this.items[index];
            let previousDeltaQuantity = 0; // Menyimpan nilai delta dari loop sebelumnya

            this.items.forEach((val, idx) => {
                // Cek kelompok item berdasarkan kode, unit, dan quantity
                if (val.item_code === currentItem.item_code &&
                    val.item_unit === currentItem.item_unit &&
                    val.quantity === currentItem.quantity) {

                    if (val.hideFields === false) {
                        // Iterasi pertama untuk kelompok item (hideFields == false)
                        if (val.pickup_qty && val.pickup_qty !== 0) {
                            // Jika ada pickup_qty, gunakan dalam perhitungan
                            val.delta_quantity = (val.pickup_qty * val.return_amount_uom) - (val.qty * val.amount_uom_receive);
                        } else if(val.qty && val.qty !== 0){
                            // Jika tidak ada pickup_qty, gunakan quantity
                            val.delta_quantity = (val.quantity * val.return_amount_uom) - (val.qty * val.amount_uom_receive);
                        }
                        // Set nilai delta_quantity ini sebagai nilai awal untuk iterasi berikutnya
                        previousDeltaQuantity = val.delta_quantity;
                        console.log("parent delta",val.delta_quantity)
                    } else {
                        // Iterasi selanjutnya dalam kelompok (hideFields == true)
                        // Menggunakan delta_quantity dari iterasi sebelumnya
                        val.delta_quantity = previousDeltaQuantity - (val.qty * val.amount_uom_receive);
                        // Perbarui nilai previousDeltaQuantity untuk digunakan dalam iterasi berikutnya
                        previousDeltaQuantity = val.delta_quantity;
                        console.log("child delta",val.delta_quantity)
                    }
                }
            });
        },
        openTab(uri) {
            window.open(uri)
        }
    },
    computed: {
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('YYYY-MM-DD');
            };
        },
    },
    watch: {
        // items: {
        //     handler(newItems) {
        //         newItems.forEach(item => {
        //             this.$watch(() => item.qty, (newQty) => {
        //                 item.delta_quantity = item.quantity - newQty;
        //             });
        //         });
        //     },
        //     deep: true,
        //     immediate: true
        // }
    }
}
</script>
